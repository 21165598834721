import React from "react"
export const TitleUnderlineOperationsHome = ({ underline }) => (
  <span>
    {underline}
    <svg
      width="213"
      height="13"
      viewBox="0 0 213 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5048 11.342C34.5803 10.3718 2.56685 13.1316 0.7326 12.9951C-1.21007 12.6788 1.13921 5.06768 2.81985 3.86024C3.84992 3.12715 3.77763 3.659 12.6597 3.12715C12.6597 3.12715 26.0506 2.35094 42.5137 1.44536C42.5137 1.44536 68.9612 0.273859 105.375 0.000748312C108.239 -0.0208131 105.971 0.431976 109.342 0.273859C116.525 -0.0495615 132.157 0.561345 137.596 0.302608C141.961 0.0941811 142.9 0.0798073 147.066 0.4751C149.93 0.74821 173.965 1.4238 176.631 1.01413C177.281 0.913514 177.751 0.978198 177.742 1.1435C177.932 1.15788 193.681 2.14251 193.753 2.29344C193.862 2.46593 194.34 2.57374 194.801 2.4875C195.651 2.32219 212.683 2.83248 212.909 3.33558C213.559 4.773 210.587 10.4508 208.499 11.7661C206.34 13.1245 195.867 10.7383 186.615 11.148C143.46 9.02779 143.795 9.56682 136.584 9.84712C135.59 9.32246 131.669 10.4508 129.338 9.55245C128.362 9.17153 121.35 9.02779 119.778 9.35121C119.444 9.42308 116.941 9.4087 113.326 9.35839C111.519 9.33683 109.432 9.3009 107.2 9.27215C104.218 9.22902 81.6563 9.03497 78.8281 9.86868C71.2201 9.03497 46.7424 10.8389 39.5048 11.342Z"
        fill="#FFBA00"
      />
    </svg>
  </span>
)

export const TitleUnderlineOperationsVoiceApiOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="279" height="11" viewBox="0 0 279 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.8705 11C46.1023 9.97886 3.8661 11.1189 0.796129 10.8111C-1.71022 10.3985 2.29514 3.13158 4.62161 2.06147C5.43707 1.6768 6.38444 1.62086 8.55501 1.79572C11.9967 2.12444 56.4994 1.47397 56.4994 1.47397C57.087 1.69778 58.8499 0.942419 59.1736 1.21519C60.193 1.85166 132.325 0.98438 138.477 0.949409C142.207 0.928427 139.257 1.36206 143.646 1.18721C153 0.837501 173.386 1.17322 180.474 0.774556C186.146 0.445832 187.369 0.431854 192.814 0.697631C198.378 0.935431 227.962 0.592715 231.344 0.0821436C232.172 -0.0577392 232.807 -0.00877595 232.807 0.152089C232.807 0.18706 253.661 0.305956 253.757 0.43185C253.913 0.592715 254.549 0.669646 255.136 0.55774C256.228 0.38988 278.425 -0.218611 278.773 0.249996C279.864 1.59287 276.806 7.31407 274.228 8.7059C271.59 10.1957 257.103 8.52407 245.459 9.41932L191.243 9.51025L179.622 10.0698C178.291 9.58717 173.17 10.8042 170.077 9.93688C168.769 9.59416 159.535 9.5452 157.497 9.90889C155.722 10.1887 108.485 9.48225 103.64 10.4544C95.006 9.64312 59.9891 10.8042 51.8705 11Z" fill="#FFA700" />
    </svg>
  </span >
)

export const TitleUnderlineOperationsVoiceApiTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="176" height="11" viewBox="0 0 176 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.7211 11C29.0824 9.97886 2.43883 11.1189 0.502217 10.8111C-1.07885 10.3985 1.44783 3.13158 2.91542 2.06147C3.42984 1.6768 4.02746 1.62086 5.39671 1.79572C7.56784 2.12444 35.6412 1.47397 35.6412 1.47397C36.0119 1.69778 37.1239 0.942419 37.3282 1.21519C37.9712 1.85166 83.4741 0.98438 87.3549 0.949409C89.7076 0.928427 87.8466 1.36206 90.6154 1.18721C96.516 0.837501 109.376 1.17322 113.847 0.774556C117.425 0.445832 118.197 0.431854 121.631 0.697631C125.142 0.935431 143.804 0.592715 145.938 0.0821436C146.459 -0.0577392 146.86 -0.00877595 146.86 0.152089C146.86 0.18706 160.016 0.305956 160.076 0.43185C160.175 0.592715 160.576 0.669646 160.946 0.55774C161.635 0.38988 175.637 -0.218611 175.857 0.249996C176.545 1.59287 174.616 7.31407 172.99 8.7059C171.325 10.1957 162.187 8.52407 154.841 9.41932L120.64 9.51025L113.31 10.0698C112.47 9.58717 109.24 10.8042 107.288 9.93688C106.464 9.59416 100.639 9.5452 99.3528 9.90889C98.2332 10.1887 68.4351 9.48225 65.3788 10.4544C59.9321 9.64312 37.8426 10.8042 32.7211 11Z" fill="#FFA700" />
    </svg>
  </span >
)

export const TitleUnderlineOperationsVoz = ({ underline }) => (
  <span>
    {underline}
    <svg width="376" height="11" viewBox="0 0 376 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69.7638 10.4961C62.0131 9.41977 5.19879 10.1556 1.07197 9.81849C-2.29604 9.38186 3.14273 2.15346 6.27936 1.10565C7.37886 0.728784 8.65345 0.681916 11.5715 0.877536C16.1982 1.23919 76.0574 1.01459 76.0574 1.01459C76.8461 1.24402 79.2224 0.505549 79.656 0.781412C81.0224 1.42762 178.044 1.25062 186.319 1.27452C191.335 1.28923 187.364 1.69462 193.268 1.56177C205.852 1.30159 233.268 1.83239 242.803 1.50155C250.435 1.22711 252.08 1.22484 259.401 1.54271C266.883 1.83375 306.675 1.77415 311.227 1.29595C312.341 1.16399 313.196 1.21904 313.195 1.3799C313.194 1.41487 341.242 1.73332 341.37 1.86013C341.578 2.02248 342.433 2.10549 343.224 1.99921C344.693 1.8418 374.552 1.44574 375.016 1.91766C376.474 3.27095 372.321 8.96274 368.843 10.3299C365.284 11.7943 345.812 9.98416 330.145 10.768L257.225 10.3401L241.592 10.7884C239.805 10.2931 232.91 11.461 228.754 10.5641C226.999 10.2089 214.58 10.0716 211.835 10.4158C209.446 10.6786 145.919 9.52014 139.396 10.4459C127.789 9.55202 80.6845 10.3779 69.7638 10.4961Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineOperationsSipTrunkOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="156" height="11" viewBox="0 0 156 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.0028 11C25.7776 9.97886 2.16169 11.1189 0.445147 10.8111C-0.956251 10.3985 1.2833 3.13158 2.58412 2.06147C3.04008 1.6768 3.5698 1.62086 4.78345 1.79572C6.70786 2.12444 31.5911 1.47397 31.5911 1.47397C31.9196 1.69778 32.9053 0.942419 33.0863 1.21519C33.6563 1.85166 73.9884 0.98438 77.4282 0.949409C79.5135 0.928427 77.864 1.36206 80.3182 1.18721C85.5483 0.837501 96.9472 1.17322 100.91 0.774556C104.082 0.445832 104.766 0.431854 107.81 0.697631C110.921 0.935431 127.463 0.592715 129.354 0.0821436C129.816 -0.0577392 130.172 -0.00877595 130.172 0.152089C130.172 0.18706 141.832 0.305956 141.886 0.43185C141.973 0.592715 142.328 0.669646 142.657 0.55774C143.267 0.38988 155.679 -0.218611 155.873 0.249996C156.483 1.59287 154.773 7.31407 153.332 8.7059C151.857 10.1957 143.757 8.52407 137.246 9.41932L106.931 9.51025L100.434 10.0698C99.6896 9.58717 96.8265 10.8042 95.0966 9.93688C94.3657 9.59416 89.2026 9.5452 88.0627 9.90889C87.0704 10.1887 60.6584 9.48225 57.9494 10.4544C53.1216 9.64312 33.5423 10.8042 29.0028 11Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineOperationsSipTrunkTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="251" height="11" viewBox="0 0 251 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6648 11C41.4755 9.97886 3.47811 11.1189 0.716231 10.8111C-1.53858 10.3985 2.0648 3.13158 4.15779 2.06147C4.89141 1.6768 5.74371 1.62086 7.69644 1.79572C10.7928 2.12444 50.8292 1.47397 50.8292 1.47397C51.3579 1.69778 52.9438 0.942419 53.2351 1.21519C54.1521 1.85166 119.045 0.98438 124.58 0.949409C127.935 0.928427 125.281 1.36206 129.23 1.18721C137.645 0.837501 155.986 1.17322 162.362 0.774556C167.465 0.445832 168.565 0.431854 173.463 0.697631C178.469 0.935431 205.084 0.592715 208.127 0.0821436C208.871 -0.0577392 209.443 -0.00877595 209.443 0.152089C209.443 0.18706 228.204 0.305956 228.291 0.43185C228.431 0.592715 229.003 0.669646 229.531 0.55774C230.513 0.38988 250.483 -0.218611 250.796 0.249996C251.777 1.59287 249.026 7.31407 246.707 8.7059C244.333 10.1957 231.301 8.52407 220.825 9.41932L172.05 9.51025L161.596 10.0698C160.398 9.58717 155.791 10.8042 153.008 9.93688C151.832 9.59416 143.525 9.5452 141.691 9.90889C140.094 10.1887 97.5977 9.48225 93.2391 10.4544C85.4714 9.64312 53.9687 10.8042 46.6648 11Z" fill="#FFA700" />
    </svg>
  </span >
)
export const TitleUnderlineOperationsVirtualPBXOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="252" height="11" viewBox="0 0 252 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6571 10.3317C41.4759 9.27365 3.47656 10.1433 0.71732 9.81597C-1.53419 9.38728 2.12031 2.14619 4.22057 1.091C4.95681 0.711551 5.80937 0.66168 7.76054 0.850421C10.854 1.20116 50.8885 0.83552 50.8885 0.83552C51.4155 1.06309 53.0065 0.319025 53.2958 0.593862C54.2082 1.23684 119.097 0.831218 124.631 0.835619C127.986 0.838506 125.329 1.25325 129.278 1.10649C137.695 0.816655 156.03 1.28284 162.408 0.92954C167.512 0.637126 168.613 0.630976 173.508 0.93159C178.511 1.205 205.125 1.05162 208.17 0.562708C208.916 0.428125 209.487 0.481154 209.486 0.642015C209.486 0.676985 228.243 0.929342 228.328 1.05585C228.467 1.21771 229.039 1.2987 229.568 1.19056C230.551 1.02969 250.522 0.563272 250.831 1.03409C251.803 2.38392 249.012 8.0854 246.683 9.4607C244.299 10.9335 231.28 9.16927 220.8 9.98998L172.032 9.73393L161.576 10.2191C160.382 9.72797 155.767 10.9121 152.99 10.0251C151.817 9.67402 143.511 9.56596 141.675 9.9166C140.077 10.185 97.5924 9.1763 93.2277 10.1175C85.4669 9.2509 53.9611 10.1878 46.6571 10.3317Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineOperationsVirtualPBXTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="196" height="12" viewBox="0 0 196 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.0088 11.0347C33.004 9.98507 3.61874 10.9161 1.48561 10.5931C-0.25473 10.1681 2.58289 2.92118 4.20866 1.86261C4.77859 1.48198 5.43793 1.43074 6.94642 1.61633C9.33796 1.96208 40.2963 1.53186 40.2963 1.53186C40.7034 1.75857 41.9349 1.01195 42.1582 1.28632C42.8626 1.92781 93.0402 1.41752 97.3194 1.413C99.9137 1.41047 97.8587 1.8295 100.913 1.67637C107.421 1.37296 121.599 1.80956 126.531 1.44598C130.479 1.14533 131.33 1.13741 135.115 1.43012C138.983 1.69545 159.563 1.49915 161.919 1.00533C162.495 0.869546 162.937 0.921653 162.936 1.08251C162.936 1.11748 177.44 1.33958 177.506 1.46595C177.613 1.62758 178.055 1.70765 178.464 1.59866C179.224 1.4362 194.668 0.937576 194.906 1.40789C195.656 2.75613 193.488 8.46205 191.685 9.84109C189.839 11.3177 179.775 9.57452 171.67 10.4121L133.959 10.2347L125.873 10.7367C124.95 10.2476 121.38 11.4392 119.234 10.5566C118.327 10.2074 111.905 10.1128 110.485 10.4664C109.248 10.7374 76.3978 9.7972 73.0211 10.7454C67.0213 9.89136 42.6571 10.8791 37.0088 11.0347Z" fill="#FFA700" />
    </svg>
  </span>
)
export const TitleUnderlineOperationsMarcadorProgresivoOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="252" height="11" viewBox="0 0 252 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6571 10.3317C41.4759 9.27365 3.47656 10.1433 0.71732 9.81597C-1.53419 9.38728 2.12031 2.14619 4.22057 1.091C4.95681 0.711551 5.80937 0.66168 7.76054 0.850421C10.854 1.20116 50.8885 0.83552 50.8885 0.83552C51.4155 1.06309 53.0065 0.319025 53.2958 0.593862C54.2082 1.23684 119.097 0.831218 124.631 0.835619C127.986 0.838506 125.329 1.25325 129.278 1.10649C137.695 0.816655 156.03 1.28284 162.408 0.92954C167.512 0.637126 168.613 0.630976 173.508 0.93159C178.511 1.205 205.125 1.05162 208.17 0.562708C208.916 0.428125 209.487 0.481154 209.486 0.642015C209.486 0.676985 228.243 0.929342 228.328 1.05585C228.467 1.21771 229.039 1.2987 229.568 1.19056C230.551 1.02969 250.522 0.563272 250.831 1.03409C251.803 2.38392 249.012 8.0854 246.683 9.4607C244.299 10.9335 231.28 9.16927 220.8 9.98998L172.032 9.73393L161.576 10.2191C160.382 9.72797 155.767 10.9121 152.99 10.0251C151.817 9.67402 143.511 9.56596 141.675 9.9166C140.077 10.185 97.5924 9.1763 93.2277 10.1175C85.4669 9.2509 53.9611 10.1878 46.6571 10.3317Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineOperationsMarcadorProgresivoTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="196" height="12" viewBox="0 0 196 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.0088 11.0347C33.004 9.98507 3.61874 10.9161 1.48561 10.5931C-0.25473 10.1681 2.58289 2.92118 4.20866 1.86261C4.77859 1.48198 5.43793 1.43074 6.94642 1.61633C9.33796 1.96208 40.2963 1.53186 40.2963 1.53186C40.7034 1.75857 41.9349 1.01195 42.1582 1.28632C42.8626 1.92781 93.0402 1.41752 97.3194 1.413C99.9137 1.41047 97.8587 1.8295 100.913 1.67637C107.421 1.37296 121.599 1.80956 126.531 1.44598C130.479 1.14533 131.33 1.13741 135.115 1.43012C138.983 1.69545 159.563 1.49915 161.919 1.00533C162.495 0.869546 162.937 0.921653 162.936 1.08251C162.936 1.11748 177.44 1.33958 177.506 1.46595C177.613 1.62758 178.055 1.70765 178.464 1.59866C179.224 1.4362 194.668 0.937576 194.906 1.40789C195.656 2.75613 193.488 8.46205 191.685 9.84109C189.839 11.3177 179.775 9.57452 171.67 10.4121L133.959 10.2347L125.873 10.7367C124.95 10.2476 121.38 11.4392 119.234 10.5566C118.327 10.2074 111.905 10.1128 110.485 10.4664C109.248 10.7374 76.3978 9.7972 73.0211 10.7454C67.0213 9.89136 42.6571 10.8791 37.0088 11.0347Z" fill="#FFA700" />
    </svg>
  </span>
)
export const TitleUnderlineOperationsMarcadorPredictivoOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="252" height="11" viewBox="0 0 252 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6571 10.3317C41.4759 9.27365 3.47656 10.1433 0.71732 9.81597C-1.53419 9.38728 2.12031 2.14619 4.22057 1.091C4.95681 0.711551 5.80937 0.66168 7.76054 0.850421C10.854 1.20116 50.8885 0.83552 50.8885 0.83552C51.4155 1.06309 53.0065 0.319025 53.2958 0.593862C54.2082 1.23684 119.097 0.831218 124.631 0.835619C127.986 0.838506 125.329 1.25325 129.278 1.10649C137.695 0.816655 156.03 1.28284 162.408 0.92954C167.512 0.637126 168.613 0.630976 173.508 0.93159C178.511 1.205 205.125 1.05162 208.17 0.562708C208.916 0.428125 209.487 0.481154 209.486 0.642015C209.486 0.676985 228.243 0.929342 228.328 1.05585C228.467 1.21771 229.039 1.2987 229.568 1.19056C230.551 1.02969 250.522 0.563272 250.831 1.03409C251.803 2.38392 249.012 8.0854 246.683 9.4607C244.299 10.9335 231.28 9.16927 220.8 9.98998L172.032 9.73393L161.576 10.2191C160.382 9.72797 155.767 10.9121 152.99 10.0251C151.817 9.67402 143.511 9.56596 141.675 9.9166C140.077 10.185 97.5924 9.1763 93.2277 10.1175C85.4669 9.2509 53.9611 10.1878 46.6571 10.3317Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineOperationsMarcadorPredictivoTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="196" height="12" viewBox="0 0 196 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.0088 11.0347C33.004 9.98507 3.61874 10.9161 1.48561 10.5931C-0.25473 10.1681 2.58289 2.92118 4.20866 1.86261C4.77859 1.48198 5.43793 1.43074 6.94642 1.61633C9.33796 1.96208 40.2963 1.53186 40.2963 1.53186C40.7034 1.75857 41.9349 1.01195 42.1582 1.28632C42.8626 1.92781 93.0402 1.41752 97.3194 1.413C99.9137 1.41047 97.8587 1.8295 100.913 1.67637C107.421 1.37296 121.599 1.80956 126.531 1.44598C130.479 1.14533 131.33 1.13741 135.115 1.43012C138.983 1.69545 159.563 1.49915 161.919 1.00533C162.495 0.869546 162.937 0.921653 162.936 1.08251C162.936 1.11748 177.44 1.33958 177.506 1.46595C177.613 1.62758 178.055 1.70765 178.464 1.59866C179.224 1.4362 194.668 0.937576 194.906 1.40789C195.656 2.75613 193.488 8.46205 191.685 9.84109C189.839 11.3177 179.775 9.57452 171.67 10.4121L133.959 10.2347L125.873 10.7367C124.95 10.2476 121.38 11.4392 119.234 10.5566C118.327 10.2074 111.905 10.1128 110.485 10.4664C109.248 10.7374 76.3978 9.7972 73.0211 10.7454C67.0213 9.89136 42.6571 10.8791 37.0088 11.0347Z" fill="#FFA700" />
    </svg>
  </span>
)